<template>
  <div id="app">
    <AuthCode/>
  </div>
</template>

<script>
import AuthCode from './components/AuthCode.vue'

export default {
  name: 'App',
  components: {
    AuthCode
  }
}
</script>

