<template>
  <div>
    <button @click="fetchAuthCode" :disabled="isFetching" class="button">
      <span v-if="!isFetching">인증코드 발급</span>
      <span v-else>발급 중...</span>
    </button>
    <div v-if="isFetching" class="spinner"></div>
    <div v-if="authCode" class="codeContainer">
      <p class="authCode">{{ authCode }}</p>
      <p class="validity" v-if="validUntil">이 코드는 {{ validUntil }}까지 유효합니다.</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      authCode: null,
      isFetching: false,
      validUntil: null,
    };
  },
  methods: {
    async fetchAuthCode() {
      this.isFetching = true;
      this.authCode = null; // 이전에 발급된 코드 제거
      this.validUntil = null; // 이전 유효 시간 제거
      setTimeout(async () => {
        try {
          const response = await fetch(process.env.VUE_APP_BMAPI2_URL, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${process.env.VUE_APP_BMAPI2_TOKEN}`,
            },
          });

          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
          }

          const data = await response.json();
          this.authCode = data.authCode;
          this.calculateValidUntil();
        } catch (error) {
          console.error("Error fetching auth code:", error);
        }
        this.isFetching = false;
      }, 5000);
    },
    calculateValidUntil() {
      const now = new Date();
      const validTime = new Date(now.getTime() + 5 * 60000); // 현재 시간에 5분을 더함
      this.validUntil = `${validTime.getHours()}:${validTime.getMinutes().toString().padStart(2, '0')}`;
    }
  },
};
</script>

<style scoped>
div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.button,
.button:disabled {
  background-color: #FFD700;
  /* 노란색 배경 */
  color: black;
  /* 검정색 텍스트 */
  padding: 14px 20px;
  margin: 10px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 80%;
  max-width: 300px;
}

.button:disabled {
  background-color: #ccc;
  /* 비활성화 시 색상 */
  cursor: not-allowed;
  /* 비활성화 시 커서 */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, .1);
  border-radius: 50%;
  border-top: 4px solid #ffd700;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.authCode {
  margin: 20px 0;
  color: #333;
  font-size: 40px;
  /* 인증코드 텍스트 크기 조정 */
  font-weight: bold;
}

.validity {
  margin-top: 20px;
  color: #555;
  font-size: 16px;
  /* 보통 크기의 글씨 */
}
</style>